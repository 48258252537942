<div class="row text-left">
    <div class="col-md-offset-2 col-md-8">
        <div class="row">
            <h3>
                Prototype Role Based Pages
            </h3>
            <span class="text-lg">
                .This is only to show the CIS roles pages and will be replaced by the Login screen
            </span>
            <hr />
        </div>
        <div class="row">
            <h4 class="blue text-center">
                Login
                <i class="ace-icon fa fa-coffee green"></i>
            </h4>

            <div class="col-md-4 col-md-offset-4">
                <input type="text" placeholder="Email" class="form-control text-left" />
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-md-offset-4">
                <input type="password" placeholder="Password" class="form-control text-left" />
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-md-offset-4 text-center">
                <button type="button"
                        class="btn btn-sm btn-primary col-md-4 form-control">                        
                    <span class="bigger-110">Login</span>
                    <i class="ace-icon fa fa-key"></i>

                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-md-offset-4 text-center">
                <p>
                    Forgot your password? <a class="pointer"><strong>Recover It</strong></a>
                </p>
                <p>
                    Don't have account? <a class="pointer"><strong>Create New</strong></a>
                </p>

            </div>
        </div>
        <hr />
        <div class="row">
            <div id="receptionist" class="col-md-3 text-center">
                <a routerLink="/receptionist/account/1">
                    <img src="assets/images/receptionist.jpg" class="prototype-home-img center-block" />
                    <span class="text-lg">
                        Receptionist
                    </span>
                </a>
            </div>
            <div id="inventoryMngr" class="col-md-3 text-center">
                <a href="#">
                    <img src="assets/images/inventoryManager.jpg" class="prototype-home-img center-block" />
                    <span class="text-lg">
                        Inventory and Purchasing Manager
                    </span>
                </a>
            </div>
            <div id="clinicAdmin" class="col-md-3 text-center">
                <a routerLink="/clinicAccount/1">
                    <img src="assets/images/clinicAdmin.png" class="prototype-home-img center-block" />
                    <span class="text-lg">
                        Clinic Admin
                    </span>
                </a>
            </div>
            <div id="sysAdmin" class="col-md-3 text-center">
                <a routerLink="/systemAdmin/account/1">
                    <img src="assets/images/systemAdmin.jpg" class="prototype-home-img center-block" />
                    <span class="text-lg">
                        System Admin
                    </span>
                </a>
            </div>
        </div>
        <br />
        <div class="row">
            <div id="insurance" class="col-md-3 text-center">
                <a routerLink="/insurance/invoice/1">
                    <img src="assets/images/insuranceSpecialist.jpg" class="prototype-home-img center-block" />
                    <span class="text-lg">
                        Insurance Specialist
                    </span>
                </a>
            </div>
            <div id="cashier" class="col-md-3 text-center">
                <a routerLink="/cashier/claims/1">
                    <img src="assets/images/cashier.jpg" class="prototype-home-img center-block" />                    
                    <span class="text-lg">Cashier</span>
                </a>
            </div>
            <div id="doctor" class="col-md-3 text-center">
                <a routerLink="/doctor/appointments">
                    <img src="assets/images/doctor.jpg" class="prototype-home-img center-block" />                   
                    <span class="text-lg">
                        Doctor
                    </span>
                </a>
            </div>
            <div id="nurse" class="col-md-3 text-center">
                <a routerLink="/nurse/account/1">
                    <img src="assets/images/nurse.jpg" class="prototype-home-img center-block" />                    
                    <span class="text-lg">
                        Nurse
                    </span>
                </a>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-md-3 text-center"></div>
            <div id="generlaUser" class="col-md-3 text-center">
                <a routerLink="/home/GeneralUser">
                    <img src="assets/images/User.jpg" class="prototype-home-img center-block" />
                    <span class="text-lg">
                        General User
                    </span>
                </a>
            </div>
            <div id="patient" class="col-md-3 text-center">
                <a routerLink="/home/Patient">
                    <img src="assets/images/patient.jpg" class="prototype-home-img center-block" />
                    <span class="text-lg">
                        Patient
                    </span>
                </a>
            </div>
            <div class="col-md-3 text-center"></div>
        </div>
        <br />
    </div>
</div>