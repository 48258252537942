<div
  class="modal fade search-modal"
  id="exampleModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "navBar.searchPopup.searchHeading" | translate }}
        </h5>
        <button
          type="button"
          class="btn-close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span>&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            placeholder=""
            aria-label="Example text with button addon"
            aria-describedby="button-addon1"
          />
          <button
            class="btn btn-outline-secondary"
            type="button"
            id="button-addon1"
          >
            {{ "navBar.searchPopup.searchButton" | translate }}
          </button>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          {{ "navBar.searchPopup.closeButton" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
