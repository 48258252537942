import {Component} from '@angular/core';

@Component({
   
    selector: 'most-popular',
    templateUrl: './most-popular.component.html'

})

export class MostPopularComponent {
}