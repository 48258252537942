import {Component} from '@angular/core';

@Component({
   
    selector: 'home-loading',
    templateUrl: './home-loading.component.html'

})

export class HomeLoadingComponent {

}