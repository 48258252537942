
<div class="features">
    <div class="container">
        <img style="    width: 700px;
        margin: auto;
        display: block;
        max-width: 100%;
        margin-bottom: 34px;" src="/assets/images/card8.png" alt="features" />
        <h2> {{"features.head"| translate}} </h2>
        <p class="main_p"> {{"features.desc"| translate}} </p>
        
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="content">
                    <h4>{{"feat_page.feat_1_head"| translate}} </h4>
                    <p> {{"feat_page.feat_1_desc"| translate}} </p>
                    <p> {{"feat_page.desc1"| translate}} </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="content">
                    <h4>{{"feat_page.feat_2_head"| translate}} </h4>
                    <p> {{"feat_page.feat_2_desc"| translate}} </p>
                    <p> {{"feat_page.desc2"| translate}} </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="content">
                    <h4>{{"feat_page.feat_3_head"| translate}} </h4>
                    <p> {{"feat_page.feat_3_desc"| translate}} </p>
                    <p> {{"feat_page.desc3"| translate}} </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="content">
                    <h4>{{"feat_page.feat_4_head"| translate}} </h4>
                    <p> {{"feat_page.feat_4_desc"| translate}} </p>
                    <p> {{"feat_page.desc4"| translate}} </p>
                    <p> {{"feat_page.desc4_2"| translate}} </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="content">
                    <h4>{{"feat_page.feat_5_head"| translate}} </h4>
                    <p> {{"feat_page.feat_5_desc"| translate}} </p>
                    <p> {{"feat_page.desc5"| translate}} </p>
                    <p> {{"feat_page.desc5_2"| translate}} </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="content">
                    <h4>{{"feat_page.feat_6_head"| translate}} </h4>
                    <p> {{"feat_page.feat_6_desc"| translate}} </p>
                    <p> {{"feat_page.desc6"| translate}} </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="content">
                    <h4>{{"feat_page.feat_7_head"| translate}} </h4>
                    <p> {{"feat_page.feat_7_desc"| translate}} </p>
                    <p> {{"feat_page.desc7"| translate}} </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="content">
                    <h4>{{"feat_page.feat_8_head"| translate}} </h4>
                    <p> {{"feat_page.feat_8_desc"| translate}} </p>
                    <p> {{"feat_page.desc8"| translate}} </p>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="contact__us">
    <div class="container">
        <div class="content text-center">
            <h2> {{"contact_with_us.head"| translate}} </h2>
            <p> {{"contact_with_us.desc"| translate}} </p>
            <a routerLink="/contact"> {{"contact_with_us.btn"| translate}}</a>
        </div>
    </div>
</section>