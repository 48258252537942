<section class="hero">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 d-flex align-items-center">
                <div class="hero_contet">
                    <h1> {{"slider.head1" | translate}}</h1>
                    <p> 
                        {{"slider.hero_p" | translate}}
                    </p>
                    <a routerLink="contact" class="hvr-rectangle-in"> {{"slider.started" | translate}} </a>

                    
                </div>
            </div>
            <div class="col-lg-6">
                <img class="w-100" src="../../../../../../assets/images/HomepageHeader.png" alt="hero">
                <!-- <img class="w-100" src="../../../../../../assets/images/HomepageHeader.jpg" alt="Header"> -->
                
            </div>
        </div>
    </div>
</section>