import {Component} from '@angular/core';

@Component({
   
    selector: 'advanced-search-home',
    templateUrl: './advanced-search.component.html'

})

export class AdvancedSearchComponent {
}