<br />
<br />
<div class="row text-center">
    <div class="col-md-offset-3 col-md-6">
        <h3>
            تسجيل حساب جديد
        </h3>
        <hr />
        <br />
        <button type="button" (click)="registerVolunteer()"
                class="width-35 btn btn-md btn-primary">            
            <span class="bigger-110">تسجيل متطوع محترف</span>
        </button>
        &nbsp;&nbsp;&nbsp;
        <button type="button" (click)="registerOrganization()"
                class="width-35 btn btn-md btn-primary">            
            <span class="bigger-110">تسجيل جمعية أهلية</span>
        </button>
    </div>
</div>
<br />
<br />
<div class="row text-center">
    <div class="col-md-offset-3 col-md-6">
        <h5>
            لديك حساب &nbsp;
            <strong>
                <a routerLink="/login">دخول</a>
            </strong>
        </h5>
    </div>
</div>
