
<br />
<span>{{ 'AccessMobileMsg' | translate}}<a routerLink="/homeSearch" >{{ 'here' | translate}}</a> </span>
<br />

<search-home [userRole]="userRole"></search-home>

<most-popular></most-popular>



<div class="hidden">
    <button #btnhideBars onclick="hideLeftBar();" ></button>
</div>

