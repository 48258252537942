<div class="about" id="about">
  <div class="container">
    <h2 class="w3_heade_tittle_agile">{{"welcome.heading"| translate}}</h2>
    <p class="sub_t_agileits">{{"welcome.p"| translate}}</p>

    <p class="ab">
      {{"welcome.description"| translate}}
    </p>

    <div class="about-w3ls row">
      <div class="col-md-6 w3about-img">
        <img src="assets/img/about.jpg" alt=" " class="img-responsive w-100" />
      </div>
      <div class="col-md-6 col-sm-12 w3about-img two">
        <div class="w3about-text">
          <h5 class="w3l-subtitle">{{"welcome.h5"| translate}}</h5>
     <p> {{"welcome.main_p"| translate}} </p>
          <div>
            <h6 style="font-size: 14px;font-weight:bold"> {{"welcome.subTit_1"| translate}} </h6>
            <p>
              {{"welcome.desc_1"| translate}}
            </p>
          </div>

          <div>
            <h6 style="font-size: 14px;font-weight:bold"> {{"welcome.subTit_2"| translate}} </h6>
            <p>
              {{"welcome.desc_2"| translate}}
            </p>
          </div>

          <div>
            <h6 style="font-size: 14px;font-weight:bold"> {{"welcome.subTit_3"| translate}} </h6>
            <p>
              {{"welcome.desc_3"| translate}}
            </p>
          </div>
          <div class="read">
            <a routerLink="/contact" class="hvr-rectangle-in">{{"welcome.readMore"| translate}}</a>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</div>

<div class="features">
  <div class="container">
    <h2> {{"features.head"| translate}} </h2>
    <p class="main_p"> {{"features.desc"| translate}} </p>
    <div class="row">
      <div class="col-lg-3">
        <div class="content">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrows-right-left" width="44"
            height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2e958e" fill="none" stroke-linecap="round"
            stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <line x1="21" y1="7" x2="3" y2="7" />
            <path d="M18 10l3 -3l-3 -3" />
            <path d="M6 20l-3 -3l3 -3" />
            <line x1="3" y1="17" x2="21" y2="17" />
          </svg>
          <h4>{{"features.feat_1_head"| translate}} </h4>
          <p> {{"features.feat_1_desc"| translate}} </p>
          <a routerLink="/features"> {{"features.view"| translate}} </a>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="content">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-file-analytics" width="44"
            height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2e958e" fill="none" stroke-linecap="round"
            stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M14 3v4a1 1 0 0 0 1 1h4" />
            <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
            <line x1="9" y1="17" x2="9" y2="12" />
            <line x1="12" y1="17" x2="12" y2="16" />
            <line x1="15" y1="17" x2="15" y2="14" />
          </svg>
          <h4>{{"features.feat_2_head"| translate}} </h4>
          <p> {{"features.feat_2_desc"| translate}} </p>
          <a routerLink="/features"> {{"features.view"| translate}} </a>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="content">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-users" width="44" height="44"
            viewBox="0 0 24 24" stroke-width="1.5" stroke="#2e958e" fill="none" stroke-linecap="round"
            stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <circle cx="9" cy="7" r="4" />
            <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
          </svg>
          <h4>{{"features.feat_3_head"| translate}} </h4>
          <p> {{"features.feat_3_desc"| translate}} </p>
          <a routerLink="/features"> {{"features.view"| translate}} </a>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="content">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-file-like" width="44" height="44"
            viewBox="0 0 24 24" stroke-width="1.5" stroke="#2e958e" fill="none" stroke-linecap="round"
            stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <rect x="3" y="16" width="3" height="5" rx="1" />
            <path
              d="M6 20a1 1 0 0 0 1 1h3.756a1 1 0 0 0 .958 -.713l1.2 -3c.09 -.303 .133 -.63 -.056 -.884c-.188 -.254 -.542 -.403 -.858 -.403h-2v-2.467a1.1 1.1 0 0 0 -2.015 -.61l-1.985 3.077v4z" />
            <path d="M14 3v4a1 1 0 0 0 1 1h4" />
            <path d="M5 12.1v-7.1a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2h-2.3" />
          </svg>
          <h4>{{"features.feat_4_head"| translate}} </h4>
          <p> {{"features.feat_4_desc"| translate}} </p>
          <a routerLink="/features"> {{"features.view"| translate}} </a>
        </div>
      </div>
    </div>
  </div>
</div>