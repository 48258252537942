import {Component} from '@angular/core';

@Component({
    selector: 'loading',
    templateUrl: './loading.component.html'

})

export class LoadingComponent {

}