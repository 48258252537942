
<form *ngIf="active" novalidate class="form-horizontal" name="resetPasswordForm"  #resetPasswordForm="ngForm">
    <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title">{{ 'ResetPassword' | translate}}</h4>
    </div>
    <div class="modal-body">
        <!--<div class="row alert alert-warning" >
            كلمة المرور يجب أن لا تقل عن ستة أحرف وأن تحتوي علي حروف وأرقام
        </div>-->
        <div class="row">
            <div class="form-group">
                <label for="txtNewPassword" class="col-md-3 col-sm-4 control-label">{{ 'NewPassword' | translate}}</label>
                <div class="col-md-9 col-sm-8">
                    <input type="password"  pattern="(?=.*\d)(?=.*[a-z]).{6,}"  class="form-control"  name="txtNewPassword" id="txtNewPassword" [(ngModel)]='model.newPassword' #txtNewPassword="ngModel" required>
                    <div [hidden]="txtNewPassword.valid || txtNewPassword.pristine" class="alert alert-danger">
                        {{ 'RequiredField' | translate}}
                    </div>
                </div>
            </div>
            <div class="form-group">
   <label for="txtConfirmPassword" class="col-md-3 col-sm-4 control-label">{{ 'ConfirmNewPassword' | translate}}
</label>
                <div class="col-md-9 col-sm-8">
                    <input type="password"  pattern="(?=.*\d)(?=.*[a-z]).{6,}"  class="form-control"  name="txtConfirmPassword" id="txtConfirmPassword" [(ngModel)]='model.confirmPassword' #txtConfirmPassword="ngModel" required>
                    <div [hidden]="txtConfirmPassword.valid || txtConfirmPassword.pristine" class="alert alert-danger">
                        {{ 'RequiredField' | translate}}

                        <!--تأكيد كلمة المرور غير متطابقة مع كلمة المرور الجديدة-->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="resetPassword()" [disabled]="!resetPasswordForm.form.valid || !enableMyAccountBtn">{{ 'ResetPassword' | translate}}</button>
        <button type="button"  disabled="{{ enableMyAccountBtn ? '' :'disabled'}}" class="btn btn-primary" data-dismiss="modal">{{ 'Cancel' | translate}}</button>
    </div>
</form>