<div class="upperNav">
  <div class="container">
    <div class="content d-flex justify-content-between">
      <div class="left-contact d-flex">
        <p>
          <img src="assets/img/upperNav/contact/location.png" alt="location" />
          <span>6th of October, 4th District, 8th Neighbourhood, Al-Yasmeen Street , Plot 1880</span>
        </p>
        <a href="mailto:vctung@outlook.com">
          <img src="assets/img/upperNav/contact/envelop.png" alt="mail" />
          <span>info@ConClinic.com</span>
        </a>
      </div>
      <div class="social">
        <a href="https://www.facebook.com/Conclinic" target="_blank">
          <img src="assets/img/upperNav/social/face.png" alt="facebook" />
        </a>
        <a href="https://www.instagram.com/conclinic_/">
          <img src="assets/img/upperNav/social/insta.png" alt="instagram" />
        </a>
        <a href="#">
          <img src="assets/img/upperNav/social/pint.png" alt="pint" />
        </a>
        <a href="#">
          <img src="assets/img/upperNav/social/twitter.png" alt="twitter" />
        </a>
      </div>
    </div>
  </div>
</div>
